
var AnchorPrice_possibleTypes = ['AnchorPrice']
module.exports.isAnchorPrice = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAnchorPrice"')
  return AnchorPrice_possibleTypes.includes(obj.__typename)
}



var AnchorPriceConnection_possibleTypes = ['AnchorPriceConnection']
module.exports.isAnchorPriceConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAnchorPriceConnection"')
  return AnchorPriceConnection_possibleTypes.includes(obj.__typename)
}



var AnchorPriceEdge_possibleTypes = ['AnchorPriceEdge']
module.exports.isAnchorPriceEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAnchorPriceEdge"')
  return AnchorPriceEdge_possibleTypes.includes(obj.__typename)
}



var AnchorPriceLineItem_possibleTypes = ['AnchorPriceLineItem']
module.exports.isAnchorPriceLineItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAnchorPriceLineItem"')
  return AnchorPriceLineItem_possibleTypes.includes(obj.__typename)
}



var AnchorPriceLineItemConnection_possibleTypes = ['AnchorPriceLineItemConnection']
module.exports.isAnchorPriceLineItemConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAnchorPriceLineItemConnection"')
  return AnchorPriceLineItemConnection_possibleTypes.includes(obj.__typename)
}



var AnchorPriceLineItemEdge_possibleTypes = ['AnchorPriceLineItemEdge']
module.exports.isAnchorPriceLineItemEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAnchorPriceLineItemEdge"')
  return AnchorPriceLineItemEdge_possibleTypes.includes(obj.__typename)
}



var AnchorPriceLot_possibleTypes = ['AnchorPriceLot']
module.exports.isAnchorPriceLot = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAnchorPriceLot"')
  return AnchorPriceLot_possibleTypes.includes(obj.__typename)
}



var AnchorPriceLotConnection_possibleTypes = ['AnchorPriceLotConnection']
module.exports.isAnchorPriceLotConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAnchorPriceLotConnection"')
  return AnchorPriceLotConnection_possibleTypes.includes(obj.__typename)
}



var AnchorPriceLotEdge_possibleTypes = ['AnchorPriceLotEdge']
module.exports.isAnchorPriceLotEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAnchorPriceLotEdge"')
  return AnchorPriceLotEdge_possibleTypes.includes(obj.__typename)
}



var AnswerOption_possibleTypes = ['AnswerOption']
module.exports.isAnswerOption = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAnswerOption"')
  return AnswerOption_possibleTypes.includes(obj.__typename)
}



var AsyncLoad_possibleTypes = ['AsyncLoad']
module.exports.isAsyncLoad = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAsyncLoad"')
  return AsyncLoad_possibleTypes.includes(obj.__typename)
}



var AwardAllPayload_possibleTypes = ['AwardAllPayload']
module.exports.isAwardAllPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAwardAllPayload"')
  return AwardAllPayload_possibleTypes.includes(obj.__typename)
}



var AwardScenario_possibleTypes = ['AwardScenario']
module.exports.isAwardScenario = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAwardScenario"')
  return AwardScenario_possibleTypes.includes(obj.__typename)
}



var AwardWinningSupplier_possibleTypes = ['AwardWinningSupplier']
module.exports.isAwardWinningSupplier = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAwardWinningSupplier"')
  return AwardWinningSupplier_possibleTypes.includes(obj.__typename)
}



var AwardWinningSupplierConnection_possibleTypes = ['AwardWinningSupplierConnection']
module.exports.isAwardWinningSupplierConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAwardWinningSupplierConnection"')
  return AwardWinningSupplierConnection_possibleTypes.includes(obj.__typename)
}



var AwardWinningSupplierEdge_possibleTypes = ['AwardWinningSupplierEdge']
module.exports.isAwardWinningSupplierEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAwardWinningSupplierEdge"')
  return AwardWinningSupplierEdge_possibleTypes.includes(obj.__typename)
}



var AwardeeSelection_possibleTypes = ['AwardeeSelection']
module.exports.isAwardeeSelection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAwardeeSelection"')
  return AwardeeSelection_possibleTypes.includes(obj.__typename)
}



var AwardeeSelectionConnection_possibleTypes = ['AwardeeSelectionConnection']
module.exports.isAwardeeSelectionConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAwardeeSelectionConnection"')
  return AwardeeSelectionConnection_possibleTypes.includes(obj.__typename)
}



var AwardeeSelectionEdge_possibleTypes = ['AwardeeSelectionEdge']
module.exports.isAwardeeSelectionEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAwardeeSelectionEdge"')
  return AwardeeSelectionEdge_possibleTypes.includes(obj.__typename)
}



var BidRequest_possibleTypes = ['BidRequest']
module.exports.isBidRequest = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBidRequest"')
  return BidRequest_possibleTypes.includes(obj.__typename)
}



var BidRequestConnection_possibleTypes = ['BidRequestConnection']
module.exports.isBidRequestConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBidRequestConnection"')
  return BidRequestConnection_possibleTypes.includes(obj.__typename)
}



var BidRequestEdge_possibleTypes = ['BidRequestEdge']
module.exports.isBidRequestEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBidRequestEdge"')
  return BidRequestEdge_possibleTypes.includes(obj.__typename)
}



var BidRequestsCounts_possibleTypes = ['BidRequestsCounts']
module.exports.isBidRequestsCounts = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBidRequestsCounts"')
  return BidRequestsCounts_possibleTypes.includes(obj.__typename)
}



var BidResponse_possibleTypes = ['BidResponse']
module.exports.isBidResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBidResponse"')
  return BidResponse_possibleTypes.includes(obj.__typename)
}



var BidResponseConnection_possibleTypes = ['BidResponseConnection']
module.exports.isBidResponseConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBidResponseConnection"')
  return BidResponseConnection_possibleTypes.includes(obj.__typename)
}



var BidResponseEdge_possibleTypes = ['BidResponseEdge']
module.exports.isBidResponseEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBidResponseEdge"')
  return BidResponseEdge_possibleTypes.includes(obj.__typename)
}



var BiddingEvent_possibleTypes = ['BiddingEvent']
module.exports.isBiddingEvent = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBiddingEvent"')
  return BiddingEvent_possibleTypes.includes(obj.__typename)
}



var BlacklistedNotification_possibleTypes = ['BlacklistedNotification']
module.exports.isBlacklistedNotification = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBlacklistedNotification"')
  return BlacklistedNotification_possibleTypes.includes(obj.__typename)
}



var BusinessUnit_possibleTypes = ['BusinessUnit']
module.exports.isBusinessUnit = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBusinessUnit"')
  return BusinessUnit_possibleTypes.includes(obj.__typename)
}



var Category_possibleTypes = ['Category']
module.exports.isCategory = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCategory"')
  return Category_possibleTypes.includes(obj.__typename)
}



var CategoryConnection_possibleTypes = ['CategoryConnection']
module.exports.isCategoryConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCategoryConnection"')
  return CategoryConnection_possibleTypes.includes(obj.__typename)
}



var CategoryEdge_possibleTypes = ['CategoryEdge']
module.exports.isCategoryEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCategoryEdge"')
  return CategoryEdge_possibleTypes.includes(obj.__typename)
}



var Company_possibleTypes = ['Company']
module.exports.isCompany = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompany"')
  return Company_possibleTypes.includes(obj.__typename)
}



var CompanyConnection_possibleTypes = ['CompanyConnection']
module.exports.isCompanyConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyConnection"')
  return CompanyConnection_possibleTypes.includes(obj.__typename)
}



var CompanyEdge_possibleTypes = ['CompanyEdge']
module.exports.isCompanyEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyEdge"')
  return CompanyEdge_possibleTypes.includes(obj.__typename)
}



var CompanyUserInviteAcceptance_possibleTypes = ['CompanyUserInviteAcceptance']
module.exports.isCompanyUserInviteAcceptance = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyUserInviteAcceptance"')
  return CompanyUserInviteAcceptance_possibleTypes.includes(obj.__typename)
}



var Counts_possibleTypes = ['Counts']
module.exports.isCounts = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCounts"')
  return Counts_possibleTypes.includes(obj.__typename)
}



var CreateAwardeeSelectionsPayload_possibleTypes = ['CreateAwardeeSelectionsPayload']
module.exports.isCreateAwardeeSelectionsPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateAwardeeSelectionsPayload"')
  return CreateAwardeeSelectionsPayload_possibleTypes.includes(obj.__typename)
}



var CustomColumnHeader_possibleTypes = ['CustomColumnHeader']
module.exports.isCustomColumnHeader = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCustomColumnHeader"')
  return CustomColumnHeader_possibleTypes.includes(obj.__typename)
}



var CustomColumnHeaderConnection_possibleTypes = ['CustomColumnHeaderConnection']
module.exports.isCustomColumnHeaderConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCustomColumnHeaderConnection"')
  return CustomColumnHeaderConnection_possibleTypes.includes(obj.__typename)
}



var CustomColumnHeaderEdge_possibleTypes = ['CustomColumnHeaderEdge']
module.exports.isCustomColumnHeaderEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCustomColumnHeaderEdge"')
  return CustomColumnHeaderEdge_possibleTypes.includes(obj.__typename)
}



var CustomColumnItem_possibleTypes = ['CustomColumnItem']
module.exports.isCustomColumnItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCustomColumnItem"')
  return CustomColumnItem_possibleTypes.includes(obj.__typename)
}



var CustomColumnItemConnection_possibleTypes = ['CustomColumnItemConnection']
module.exports.isCustomColumnItemConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCustomColumnItemConnection"')
  return CustomColumnItemConnection_possibleTypes.includes(obj.__typename)
}



var CustomColumnItemEdge_possibleTypes = ['CustomColumnItemEdge']
module.exports.isCustomColumnItemEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCustomColumnItemEdge"')
  return CustomColumnItemEdge_possibleTypes.includes(obj.__typename)
}



var Division_possibleTypes = ['Division']
module.exports.isDivision = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDivision"')
  return Division_possibleTypes.includes(obj.__typename)
}



var DivisionConnection_possibleTypes = ['DivisionConnection']
module.exports.isDivisionConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDivisionConnection"')
  return DivisionConnection_possibleTypes.includes(obj.__typename)
}



var DivisionEdge_possibleTypes = ['DivisionEdge']
module.exports.isDivisionEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDivisionEdge"')
  return DivisionEdge_possibleTypes.includes(obj.__typename)
}



var EmailBidSubmission_possibleTypes = ['EmailBidSubmission']
module.exports.isEmailBidSubmission = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmailBidSubmission"')
  return EmailBidSubmission_possibleTypes.includes(obj.__typename)
}



var EmailBidSubmissionConnection_possibleTypes = ['EmailBidSubmissionConnection']
module.exports.isEmailBidSubmissionConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmailBidSubmissionConnection"')
  return EmailBidSubmissionConnection_possibleTypes.includes(obj.__typename)
}



var EmailBidSubmissionEdge_possibleTypes = ['EmailBidSubmissionEdge']
module.exports.isEmailBidSubmissionEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmailBidSubmissionEdge"')
  return EmailBidSubmissionEdge_possibleTypes.includes(obj.__typename)
}



var Event_possibleTypes = ['Event']
module.exports.isEvent = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEvent"')
  return Event_possibleTypes.includes(obj.__typename)
}



var Firm_possibleTypes = ['Firm']
module.exports.isFirm = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFirm"')
  return Firm_possibleTypes.includes(obj.__typename)
}



var LineItem_possibleTypes = ['LineItem']
module.exports.isLineItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLineItem"')
  return LineItem_possibleTypes.includes(obj.__typename)
}



var LineItemConnection_possibleTypes = ['LineItemConnection']
module.exports.isLineItemConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLineItemConnection"')
  return LineItemConnection_possibleTypes.includes(obj.__typename)
}



var LineItemEdge_possibleTypes = ['LineItemEdge']
module.exports.isLineItemEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLineItemEdge"')
  return LineItemEdge_possibleTypes.includes(obj.__typename)
}



var Lot_possibleTypes = ['Lot']
module.exports.isLot = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLot"')
  return Lot_possibleTypes.includes(obj.__typename)
}



var LotConnection_possibleTypes = ['LotConnection']
module.exports.isLotConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLotConnection"')
  return LotConnection_possibleTypes.includes(obj.__typename)
}



var LotEdge_possibleTypes = ['LotEdge']
module.exports.isLotEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLotEdge"')
  return LotEdge_possibleTypes.includes(obj.__typename)
}



var Mutation_possibleTypes = ['Mutation']
module.exports.isMutation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMutation"')
  return Mutation_possibleTypes.includes(obj.__typename)
}



var NextDeadline_possibleTypes = ['NextDeadline']
module.exports.isNextDeadline = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNextDeadline"')
  return NextDeadline_possibleTypes.includes(obj.__typename)
}



var PageInfo_possibleTypes = ['PageInfo']
module.exports.isPageInfo = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPageInfo"')
  return PageInfo_possibleTypes.includes(obj.__typename)
}



var Query_possibleTypes = ['Query']
module.exports.isQuery = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isQuery"')
  return Query_possibleTypes.includes(obj.__typename)
}



var Recommendation_possibleTypes = ['Recommendation']
module.exports.isRecommendation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecommendation"')
  return Recommendation_possibleTypes.includes(obj.__typename)
}



var RecommendationConnection_possibleTypes = ['RecommendationConnection']
module.exports.isRecommendationConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecommendationConnection"')
  return RecommendationConnection_possibleTypes.includes(obj.__typename)
}



var RecommendationEdge_possibleTypes = ['RecommendationEdge']
module.exports.isRecommendationEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecommendationEdge"')
  return RecommendationEdge_possibleTypes.includes(obj.__typename)
}



var RecommendationsCounts_possibleTypes = ['RecommendationsCounts']
module.exports.isRecommendationsCounts = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecommendationsCounts"')
  return RecommendationsCounts_possibleTypes.includes(obj.__typename)
}



var RecommendedSupplier_possibleTypes = ['RecommendedSupplier']
module.exports.isRecommendedSupplier = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecommendedSupplier"')
  return RecommendedSupplier_possibleTypes.includes(obj.__typename)
}



var ResponseLineItem_possibleTypes = ['ResponseLineItem']
module.exports.isResponseLineItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isResponseLineItem"')
  return ResponseLineItem_possibleTypes.includes(obj.__typename)
}



var Role_possibleTypes = ['Role']
module.exports.isRole = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRole"')
  return Role_possibleTypes.includes(obj.__typename)
}



var Round_possibleTypes = ['Round']
module.exports.isRound = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRound"')
  return Round_possibleTypes.includes(obj.__typename)
}



var SaveMultiAwardeeSelectionsByLineItemPayload_possibleTypes = ['SaveMultiAwardeeSelectionsByLineItemPayload']
module.exports.isSaveMultiAwardeeSelectionsByLineItemPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSaveMultiAwardeeSelectionsByLineItemPayload"')
  return SaveMultiAwardeeSelectionsByLineItemPayload_possibleTypes.includes(obj.__typename)
}



var Scorer_possibleTypes = ['Scorer']
module.exports.isScorer = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScorer"')
  return Scorer_possibleTypes.includes(obj.__typename)
}



var Supplier_possibleTypes = ['Supplier']
module.exports.isSupplier = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSupplier"')
  return Supplier_possibleTypes.includes(obj.__typename)
}



var SupplierConnection_possibleTypes = ['SupplierConnection']
module.exports.isSupplierConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSupplierConnection"')
  return SupplierConnection_possibleTypes.includes(obj.__typename)
}



var SupplierEdge_possibleTypes = ['SupplierEdge']
module.exports.isSupplierEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSupplierEdge"')
  return SupplierEdge_possibleTypes.includes(obj.__typename)
}



var SupplierQualAnswer_possibleTypes = ['SupplierQualAnswer']
module.exports.isSupplierQualAnswer = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSupplierQualAnswer"')
  return SupplierQualAnswer_possibleTypes.includes(obj.__typename)
}



var SupplierStatus_possibleTypes = ['SupplierStatus']
module.exports.isSupplierStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSupplierStatus"')
  return SupplierStatus_possibleTypes.includes(obj.__typename)
}



var Transaction_possibleTypes = ['Transaction']
module.exports.isTransaction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTransaction"')
  return Transaction_possibleTypes.includes(obj.__typename)
}



var TransactionConnection_possibleTypes = ['TransactionConnection']
module.exports.isTransactionConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTransactionConnection"')
  return TransactionConnection_possibleTypes.includes(obj.__typename)
}



var TransactionDivision_possibleTypes = ['TransactionDivision']
module.exports.isTransactionDivision = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTransactionDivision"')
  return TransactionDivision_possibleTypes.includes(obj.__typename)
}



var TransactionDivisionConnection_possibleTypes = ['TransactionDivisionConnection']
module.exports.isTransactionDivisionConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTransactionDivisionConnection"')
  return TransactionDivisionConnection_possibleTypes.includes(obj.__typename)
}



var TransactionDivisionEdge_possibleTypes = ['TransactionDivisionEdge']
module.exports.isTransactionDivisionEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTransactionDivisionEdge"')
  return TransactionDivisionEdge_possibleTypes.includes(obj.__typename)
}



var TransactionEdge_possibleTypes = ['TransactionEdge']
module.exports.isTransactionEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTransactionEdge"')
  return TransactionEdge_possibleTypes.includes(obj.__typename)
}



var TransactionSupplier_possibleTypes = ['TransactionSupplier']
module.exports.isTransactionSupplier = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTransactionSupplier"')
  return TransactionSupplier_possibleTypes.includes(obj.__typename)
}



var TransactionSupplierConnection_possibleTypes = ['TransactionSupplierConnection']
module.exports.isTransactionSupplierConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTransactionSupplierConnection"')
  return TransactionSupplierConnection_possibleTypes.includes(obj.__typename)
}



var TransactionSupplierEdge_possibleTypes = ['TransactionSupplierEdge']
module.exports.isTransactionSupplierEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTransactionSupplierEdge"')
  return TransactionSupplierEdge_possibleTypes.includes(obj.__typename)
}



var UpdateAwardeeSelectionsPayload_possibleTypes = ['UpdateAwardeeSelectionsPayload']
module.exports.isUpdateAwardeeSelectionsPayload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateAwardeeSelectionsPayload"')
  return UpdateAwardeeSelectionsPayload_possibleTypes.includes(obj.__typename)
}



var User_possibleTypes = ['User']
module.exports.isUser = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUser"')
  return User_possibleTypes.includes(obj.__typename)
}



var UserConnection_possibleTypes = ['UserConnection']
module.exports.isUserConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserConnection"')
  return UserConnection_possibleTypes.includes(obj.__typename)
}



var UserEdge_possibleTypes = ['UserEdge']
module.exports.isUserEdge = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserEdge"')
  return UserEdge_possibleTypes.includes(obj.__typename)
}



var VendorOrganization_possibleTypes = ['VendorOrganization']
module.exports.isVendorOrganization = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isVendorOrganization"')
  return VendorOrganization_possibleTypes.includes(obj.__typename)
}
