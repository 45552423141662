
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import type { AppProps } from 'next/app';
import { colorBorder, AdsConfigProvider, gray10 } from '@arkestro/arkestro-design-system';
import { withLDProvider, LDEvaluationDetail, LDOptions } from 'launchdarkly-react-client-sdk';
import Head from 'next/head';
import { ComponentType, useEffect } from 'react';
import { useRouter } from 'next/router';
import AppConfig from '@config/AppConfig';
import AxiosService from '@services/AxiosService';
import '@styles/globals.scss';
import App from '@components/App/App';
import ConfigurationsProvider from '@components/providers/ConfigurationsProvider/ConfigurationsProvider';
import UserProvider from '@components/providers/UserProvider/UserProvider';
import PageErrorBoundary from '@components/shared/ErrorBoundaries/PageErrorBoundary';
import SegmentService from '@services/SegmentService';
import IntercomService from '@services/IntercomService';
import { datadogRum } from '@datadog/browser-rum';
export const PRIMARY_WINDOW_NAME = 'ArkestroAppWindow';
declare const window: Window & {
    analytics?: {
        page: () => void;
    };
};
// Setup defaults and refresh token handling
AxiosService.setup();
const AppPage = ({ Component, pageProps }: AppProps) => {
    const router = useRouter();
    useEffect(() => {
        // Set the name of the open window to reference elsewhere.
        if (typeof window !== 'undefined') {
            window.name = PRIMARY_WINDOW_NAME;
            // Ensure Segment tracks route changes within our Single Page App.
            // This will help with tools like Satismeter detecting route changes as page loads.
            router.events.on('routeChangeComplete', () => {
                if (!window.analytics?.page)
                    return;
                window.analytics.page();
            });
        }
        // Loads Segment Analytics
        SegmentService.initialize();
        IntercomService.initialize();
    }, []);
    return (<>
      {/* Needed to isolate antd v5 styles from antd v3 for v5 components. */}
      <AdsConfigProvider theme={{
            components: {
                Badge: {
                    dotSize: 8,
                },
                Checkbox: {
                    colorBorder,
                    colorPrimary: gray10,
                    colorPrimaryHover: gray10,
                },
                Collapse: {
                    headerBg: 'white',
                },
                InputNumber: {
                    addonBg: 'white',
                },
            },
        }}>
        <Head>
          <meta content="noindex" name="robots"/>
          <meta content="width=device-width, initial-scale=1.0" name="viewport"/>
        </Head>
        <PageErrorBoundary>
          <ConfigurationsProvider>
            <UserProvider>
              <App Component={Component} pageProps={pageProps}/>
            </UserProvider>
          </ConfigurationsProvider>
        </PageErrorBoundary>
      </AdsConfigProvider>
    </>);
};
const context = {
    kind: 'user',
    // Explicitly set "anonymous" when not authenticated.
    // Handles false MAUs in Synthetics.
    key: 'unauthenticated',
};
const options: LDOptions = {
    // Default for this is false, but we use true when we need a reason why a flag is on/off.
    // https://docs.launchdarkly.com/sdk/concepts/evaluation-reasons#understanding-the-reason-data
    evaluationReasons: false,
    inspectors: [
        {
            type: 'flag-used',
            name: 'dd-inspector',
            method: (key: string, detail: LDEvaluationDetail) => {
                datadogRum.addFeatureFlagEvaluation(key, detail.value);
            }
        }
    ]
};
const __Next_Translate__Page__195f19b55b2__ = withLDProvider({
    clientSideID: AppConfig.launchDarklyClientSideID!,
    context,
    options,
})(AppPage as ComponentType<{}>);

    export default __appWithI18n(__Next_Translate__Page__195f19b55b2__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  